const loadScript = (url: string): Promise<HTMLScriptElement> =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.onload = () => resolve(script);
    script.onerror = () => reject(new Error(`Failed to load script: ${url}`));
    script.src = url;

    document.head.appendChild(script);
  });

export default loadScript;
