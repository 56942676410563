const productionDependencies = {
  imports: {
    'single-spa': '/core/single-spa@6.0.3/single-spa.min.js',
    react: '/core/es2020/react@18.2.0.js',
    'react-dom': '/core/es2020/react-dom@18.2.0.js',
    'react-dom/client': '/core/es2020/react-dom-client@18.2.0.js',
    lodash: '/core/es2020/lodash-es@4.17.21.js',
  },
};

const developmentDependencies = {
  imports: {
    'single-spa': '/core/single-spa@6.0.3/single-spa.dev.js',
    react: 'https://esm.sh/react@18.2.0?target=es2020&dev',
    'react-dom': 'https://esm.sh/react-dom@18.2.0?target=es2020&dev',
    'react-dom/client': 'https://esm.sh/react-dom@18.2.0/client?target=es2020&dev',
    lodash: '/core/es2020/lodash-es@4.17.21.js',
  },
};

const coreDependencies = IS_LOCAL_MODE ? developmentDependencies : productionDependencies;

export default coreDependencies;

export { productionDependencies, developmentDependencies };
