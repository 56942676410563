import { developmentDependencies } from '../config/coreDependencies';
import urlsConfig from '../config/urlsConfig';
import loadScript from '../infrastructure/loadScript';

const useImportmapOverrides = (): boolean => localStorage.getItem('overrides-ui') === 'true';

const loadMetaDomains = () => {
  const meta = document.createElement('meta');
  meta.dataset.testid = 'importmap-override-domains';
  meta.name = 'importmap-override-domains';
  meta.content = 'allowlist:*.test.abaconnectemr.com,localhost,uat.abaconnectemr.com';

  document.body.appendChild(meta);
};

const loadUI = () => {
  const ui = document.createElement('import-map-overrides-full');
  ui.dataset.testid = 'importmap-override-ui';

  document.body.appendChild(ui);
};

const overrideCoreDependencies = () => {
  localStorage.setItem('import-map-override:single-spa', developmentDependencies.imports['single-spa']);
  localStorage.setItem('import-map-override:react', developmentDependencies.imports.react);
  localStorage.setItem('import-map-override:react-dom', developmentDependencies.imports['react-dom']);
  localStorage.setItem('import-map-override:react-dom/client', developmentDependencies.imports['react-dom/client']);
};

const loadImportmapOverridesScript = async () => {
  loadMetaDomains();
  loadUI();
  await loadScript(urlsConfig.importOverrideUrl);
  overrideCoreDependencies();
};

const loadImportmapOverrides = () => (useImportmapOverrides() ? loadImportmapOverridesScript() : Promise.resolve());

export default loadImportmapOverrides;

export { useImportmapOverrides };
