import coreDependencies from '../config/coreDependencies';
import { ImportmapDTO } from '../core/Importmap';
import fetchImportmap from '../infrastructure/fetchImportmap';

import loadImportmapOverrides, { useImportmapOverrides } from './loadImportmapOverrides';

const getImportmapType = (): string => (useImportmapOverrides() ? 'overridable-importmap' : 'importmap');

const addImportmapScript = (node: Element, importmap: ImportmapDTO): Element => {
  const script = document.createElement('script');

  script.type = getImportmapType();
  script.textContent = JSON.stringify(importmap);

  node.parentNode.insertBefore(script, node.nextSibling);

  return script;
};

const addImportMap = (mfeImportmap: ImportmapDTO) => {
  window.ABA_IMPORTMAP = mfeImportmap;
  const importmap: ImportmapDTO = {
    imports: {
      ...mfeImportmap.imports,
      ...coreDependencies.imports,
    },
  };
  const importmapMeta = document.head.querySelector('meta[name="importmap-type"]');
  addImportmapScript(importmapMeta, importmap);
};

const loadRootConfig = () => import(/* webpackIgnore: true */ '@abatech/root-config');

const handleImportMapError = (error: Error) => {
  const div = document.createElement('div');
  div.textContent = error.message;
  div.style.color = 'red';
  document.body.appendChild(div);

  throw error;
};

const bootstrapApplication = () =>
  fetchImportmap()
    // prettier-ignore
    .then(addImportMap)
    .then(loadImportmapOverrides)
    .then(loadRootConfig)
    .catch(handleImportMapError);

export default bootstrapApplication;
